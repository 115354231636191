import type { IntlShape } from 'react-intl';
// All relevant files for data definition to be used for the service; i.e. table configuration; initial service data
// or configurations, date formatter, currencies, etc.

export const Category = {
    SERVICES: 'services',
    NEWS: 'news',
    EARLY_ACCESS: 'earlyAccess',
    EXCEPTIONS: 'exceptions',
    ADMINISTRATION: 'administration',
    MARKETPLACE: 'marketplace',
    CUSTOMERCENTER: 'customercenter',
    SETTINGS: 'settings',
    SUPPORT: 'support',
} as const;

export const ServicesCategoriesList: string[] = [
    Category.SERVICES,
    Category.EXCEPTIONS,
    Category.ADMINISTRATION,
    Category.MARKETPLACE,
    Category.CUSTOMERCENTER,
];

export type CategoryType = (typeof Category)[keyof typeof Category];

export type ServiceItem = {
    id: string;
    sortId: number;
    title: string;
    description?: string;
    icon?: string;
    externalIcon?: string;
    link?: string;
    highlight?: boolean;
    badge?: 'Beta' | 'New';

    // Used to know which tile should be rendered on which page
    category?: CategoryType;
    // Used to group multiple tiles into a group for a single page
    group?: string;
    // Service tags are used to link a service tile to respective filter on a page
    serviceTags?: string[];
    // Search tags are used to search items for translated values. These tags are defined in Phrase
    // and are translated
    searchTags?: string[];
};

export type UntranslatedServiceItem = Omit<ServiceItem, 'link'> & { link?: string | ((intl: IntlShape) => string) };

export type Group = {
    [key: string]: {
        title?: string;
    };
};

export type GroupedItems = {
    [key: string]: ServiceItem[];
};

export type ServiceMap = {
    [key: string]: UntranslatedServiceItem;
};

export const administrationGroups: Group = {
    assetsList: {
        title: 'intl-msg:extendedMenu.group.fleetManagement',
    },
    usersList: {
        title: 'intl-msg:extendedMenu.group.userManagement',
    },
    driversList: {
        title: 'intl-msg:extendedMenu.group.driverManagement',
    },
};

export const exceptionsGroups: Group = {
    eventsAndRules: {
        title: 'intl-msg:extendedMenu.group.eventsAndRules',
    },
    ruleTemplates: {
        title: 'intl-msg:extendedMenu.group.ruleTemplates',
    },
};

export const marketplaceGroups: Group = {
    category: {
        title: 'intl-msg:extendedMenu.group.serviceCategories',
    },
    rio: {
        title: 'intl-msg:extendedMenu.group.mixedfleet',
    },
    man: {
        title: 'intl-msg:extendedMenu.group.man',
    },
};

export const customercenterGroups: Group = {
    bookings: {
        title: 'intl-msg:extendedMenu.group.bookings',
    },
    additional: {
        title: 'intl-msg:extendedMenu.group.customercenter',
    },
};

const services: ServiceMap = {
    pdc: {
        id: 'pdc',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.pdc',
        description: 'intl-msg:glossary.serviceDescription.pdc',
        link: 'https://pdc.vehicleinspection.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/PDC.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    performance: {
        id: 'performance',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.performance',
        description: 'intl-msg:glossary.serviceDescription.performance',
        link: 'https://perform.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Perform.svg',
        category: Category.SERVICES,
        serviceTags: ['driver'],
    },
    timed: {
        id: 'timed',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.timed',
        description: 'intl-msg:glossary.serviceDescription.timed',
        link: 'https://drivingtime.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Timed.svg',
        category: Category.SERVICES,
        serviceTags: ['driver'],
    },
    manemanager: {
        id: 'manemanager',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.manemanager',
        description: 'intl-msg:glossary.serviceDescription.manemanager',
        link: 'https://ota-charging.rio.cloud/#vehicles',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_eManager.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    servicecareadministration: {
        id: 'servicecareadministration',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.servicecareadministration',
        description: 'intl-msg:glossary.serviceDescription.servicecareadministration',
        link: 'https://manservicecare.rio.cloud',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_ServiceCare.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    tachographservices: {
        id: 'tachographservices',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.tachographservices',
        description: 'intl-msg:glossary.serviceDescription.tachographservices',
        link: 'https://remotedownload.rio.cloud',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Compliant.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    mannow: {
        id: 'mannow',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.mannow',
        description: 'intl-msg:glossary.serviceDescription.mannow',
        link: 'https://mannow.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_Now.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    webtms: {
        id: 'webtms',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.webtms',
        description: 'intl-msg:glossary.serviceDescription.webtms',
        link: 'https://webtms.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/WebTMS.svg',
        category: Category.SERVICES,
        serviceTags: ['order'],
    },
    tms: {
        id: 'tms',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.tms',
        description: 'intl-msg:glossary.serviceDescription.tms',
        link: 'https://transportmanagement.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/TransportManagementSystem.svg',
        category: Category.SERVICES,
        serviceTags: ['order'],
    },
    mansimplepay: {
        id: 'mansimplepay',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.mansimplepay',
        description: 'intl-msg:glossary.serviceDescription.mansimplepay',
        link: 'https://simplepay.rio.cloud',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Jupiter.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
        badge: 'Beta',
    },
    mansimplepaytest: {
        id: 'mansimplepaytest',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.mansimplepaytest',
        description: 'intl-msg:glossary.serviceDescription.mansimplepaytest',
        link: 'https://jupiter.rio.cloud',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Jupiter.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    'latam-e-fleet': {
        id: 'latam-e-fleet',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.latam-e-fleet',
        description: 'intl-msg:glossary.serviceDescription.latam-e-fleet',
        link: 'https://latam-efleet.rio.cloud/#dashboard',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/LATAM-Electric-Plug.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    'latam-maintenance': {
        id: 'latam-maintenance',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.latam-maintenance',
        description: 'intl-msg:glossary.serviceDescription.latam-maintenance',
        link: 'https://latam-maintenance.rio.cloud/#dashboard',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/LATAM-Maintenance.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    'latam-performance': {
        id: 'latam-performance',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.latam-performance',
        description: 'intl-msg:glossary.serviceDescription.latam-performance',
        link: 'https://latam-perform.rio.cloud/#dashboard',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/LATAM-Performance.svg',
        category: Category.SERVICES,
        serviceTags: ['driver'],
    },
    'latam-fleet-master': {
        id: 'latam-fleet-master',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.latam-fleet-master',
        description: 'intl-msg:glossary.serviceDescription.latam-fleet-master',
        link: 'https://latam-fleet-master.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/LATAM-Data-Sharing.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    outboundOrderBook: {
        id: 'outboundOrderBook',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.outboundOrderBook',
        description: 'intl-msg:glossary.serviceDescription.outboundOrderBook',
        link: 'https://outbound-order-book.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/OutboundOrderBook.svg',
        category: Category.SERVICES,
        serviceTags: ['order'],
        badge: 'New',
    },
    fleet: {
        id: 'fleet',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.fleet',
        description: 'intl-msg:glossary.serviceDescription.fleet',
        link: 'https://cartright-basic.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Fleet.svg',
        category: Category.SERVICES,
        serviceTags: ['order'],
        badge: 'Beta',
    },
    mansmartroute: {
        id: 'mansmartroute',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.mansmartroute',
        description: 'intl-msg:glossary.serviceDescription.mansmartroute',
        link: 'https://smart-route.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/SmartRoutePlanning.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
        badge: 'Beta',
    },
    fleetmonitor: {
        id: 'fleetmonitor',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.fleetmonitor',
        description: 'intl-msg:glossary.serviceDescription.fleetmonitor',
        link: 'https://livemonitor.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Fleetmonitor.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    volksblocker: {
        id: 'volksblocker',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.volksblocker',
        description: 'intl-msg:glossary.serviceDescription.volksblocker',
        link: 'https://latam-remote-blocking.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/LATAM-Volksblocker.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
    manchargeandgo: {
        id: 'manchargeandgo',
        sortId: 0,
        title: 'intl-msg:glossary.serviceName.manchargeandgo',
        description: 'intl-msg:glossary.serviceDescription.manchargeandgo',
        link: 'https://manchargego.rio.cloud/',
        externalIcon: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_Charge_And_Go.svg',
        category: Category.SERVICES,
        serviceTags: ['vehicle'],
    },
};

const adminLinks: ServiceMap = {
    assetAdministration: {
        id: 'assetAdministration',
        sortId: 1.1,
        title: 'intl-msg:extendedMenu.linkTile.name.fleet',
        description: 'intl-msg:extendedMenu.linkTile.description.fleet',
        link: 'https://administration.assets.rio.cloud/#/assets',
        category: Category.ADMINISTRATION,
        group: 'assetsList',
        icon: 'truck',
    },
    newAsset: {
        id: 'newAsset',
        sortId: 1.2,
        title: 'intl-msg:extendedMenu.linkTile.name.newVehicle',
        description: 'intl-msg:extendedMenu.linkTile.description.newVehicle',
        link: 'https://administration.assets.rio.cloud/#/assets?createAssetModal=show',
        category: Category.ADMINISTRATION,
        group: 'assetsList',
        icon: 'plus',
    },
    fleetGroups: {
        id: 'fleetGroups',
        sortId: 1.3,
        title: 'intl-msg:extendedMenu.linkTile.name.fleetGroups',
        description: 'intl-msg:extendedMenu.linkTile.description.fleetGroups',
        link: 'https://administration.tags.rio.cloud',
        category: Category.ADMINISTRATION,
        group: 'assetsList',
        icon: 'group',
    },
    userAdministration: {
        id: 'userAdministration',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.user',
        description: 'intl-msg:extendedMenu.linkTile.description.user',
        link: 'https://users.iam.rio.cloud/#/users',
        category: Category.ADMINISTRATION,
        group: 'usersList',
        icon: 'user',
    },
    newUser: {
        id: 'newUser',
        sortId: 2.2,
        title: 'intl-msg:extendedMenu.linkTile.name.newUser',
        description: 'intl-msg:extendedMenu.linkTile.description.newUser',
        link: 'https://users.iam.rio.cloud/#/users?createUserModal=show',
        category: Category.ADMINISTRATION,
        group: 'usersList',
        icon: 'plus',
    },
    userGroups: {
        id: 'userGroups',
        sortId: 2.3,
        title: 'intl-msg:extendedMenu.linkTile.name.userGroups',
        description: 'intl-msg:extendedMenu.linkTile.description.userGroups',
        link: 'https://users.iam.rio.cloud/#/groups',
        category: Category.ADMINISTRATION,
        group: 'usersList',
        icon: 'group',
    },
    driverAdministration: {
        id: 'driverAdministration',
        sortId: 3.1,
        title: 'intl-msg:extendedMenu.linkTile.name.driver',
        description: 'intl-msg:extendedMenu.linkTile.description.driver',
        link: 'https://drivers.rio.cloud/#/drivers',
        category: Category.ADMINISTRATION,
        group: 'driversList',
        icon: 'driver',
    },
    inviteDriver: {
        id: 'inviteDriver',
        sortId: 3.2,
        title: 'intl-msg:extendedMenu.linkTile.name.inviteDriver',
        description: 'intl-msg:extendedMenu.linkTile.description.inviteDriver',
        link: 'https://drivers.rio.cloud/#/drivers?createDriverModal=show',
        category: Category.ADMINISTRATION,
        group: 'driversList',
        icon: 'plus',
    },
    driverGroups: {
        id: 'driverGroups',
        sortId: 3.3,
        title: 'intl-msg:extendedMenu.linkTile.name.driverGroups',
        description: 'intl-msg:extendedMenu.linkTile.description.driverGroups',
        link: 'https://administration.tags.rio.cloud/',
        category: Category.ADMINISTRATION,
        group: 'driversList',
        icon: 'group',
    },
};

const exceptionLinks: ServiceMap = {
    allEvents: {
        id: 'allEvents',
        sortId: 1.1,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.allEvents',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.allEvents',
        link: 'https://event-monitor.rio.cloud/#/events',
        category: Category.EXCEPTIONS,
        group: 'eventsAndRules',
        icon: 'notification',
    },
    allRules: {
        id: 'allRules',
        sortId: 1.2,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.allRules',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.allRules',
        link: 'https://event-monitor.rio.cloud/#/rules',
        category: Category.EXCEPTIONS,
        group: 'eventsAndRules',
        icon: 'cards-list',
    },
    newRule: {
        id: 'newRule',
        sortId: 1.3,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.newRule',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.newRule',
        link: 'https://event-monitor.rio.cloud/#/rules/create',
        category: Category.EXCEPTIONS,
        group: 'eventsAndRules',
        icon: 'plus',
    },
    uselessFuelConsumption: {
        id: 'uselessFuelConsumption',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.uselessFuelConsumption',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.uselessFuelConsumption',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.uselessFuelConsumption' })
            )}&scope=%28type%3AACCOUNT%29&condition=%28type%3AASSET_MAXIMUM_SPEED%2CvalueInKmh%3A85%29&minimumMatchDuration=5`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'filling-station',
    },
    mileageOfLeasedVehicles: {
        id: 'mileageOfLeasedVehicles',
        sortId: 2.2,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.mileageOfLeasedVehicles',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.mileageOfLeasedVehicles',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.mileageOfLeasedVehicles' })
            )}&scope=%28type%3ATAG_ASSET%29&condition=%28type%3AASSET_MAXIMUM_MILEAGE%2CvalueInKm%3A375000%29`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'milage',
    },
    enterNoGoArea: {
        id: 'enterNoGoArea',
        sortId: 2.3,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.enterNoGoArea',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.enterNoGoArea',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.enterNoGoArea' })
            )}&scope=%28type%3ATAG_ASSET%29&condition=%28type%3AGEOFENCE%2Cstatus%3AINSIDE_GEOFENCE%29`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'ban-circle',
    },
    speedInFactory: {
        id: 'speedInFactory',
        sortId: 2.4,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.speedInFactory',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.speedInFactory',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.speedInFactory' })
            )}&scope=%28type%3AACCOUNT%29&condition=%28type%3AGEOFENCE%2Cstatus%3AINSIDE_GEOFENCE%29&condition=%28type%3AASSET_MAXIMUM_SPEED%2CvalueInKmh%3A25%29`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'speed',
    },
    billableDowntime: {
        id: 'billableDowntime',
        sortId: 2.5,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.billableDowntime',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.billableDowntime',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.billableDowntime' })
            )}&scope=%28type%3AACCOUNT%29&condition=%28type%3AGEOFENCE%2Cstatus%3AINSIDE_GEOFENCE%29&minimumMatchDuration=180`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'cost-efficency',
    },
    missingDriverCard: {
        id: 'missingDriverCard',
        sortId: 2.6,
        title: 'intl-msg:extendedMenu.linkTile.name.exception.missingDriverCard',
        description: 'intl-msg:extendedMenu.linkTile.description.exception.missingDriverCard',
        link: intl =>
            `https://event-monitor.rio.cloud/#/rules/create?name=${encodeURIComponent(
                intl.formatMessage({ id: 'intl-msg:extendedMenu.linkTile.name.exception.missingDriverCard' })
            )}&scope=%28type%3AACCOUNT%29&condition=%28type%3ADRIVING_STATE%2Cstatus%3ADRIVING%29&condition=%28type%3ADRIVER_CARD%2Cstatus%3AMISSING%29&condition=%28type%3AGEOFENCE%2Cstatus%3AOUTSIDE_GEOFENCE%29&minimumMatchDuration=1`,
        category: Category.EXCEPTIONS,
        group: 'ruleTemplates',
        icon: 'drivercard-out',
    },
};

const marketplaceLinks: ServiceMap = {
    marketplaceCategoryVehicle: {
        id: 'marketplaceCategoryVehicle',
        sortId: 1.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.vehicle',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.vehicle',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/overview#c13294`,
        category: Category.MARKETPLACE,
        group: 'category',
        icon: 'truck',
    },
    marketplaceCategoryDriver: {
        id: 'marketplaceCategoryDriver',
        sortId: 1.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.driver',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.driver',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/overview#c51809`,
        category: Category.MARKETPLACE,
        group: 'category',
        icon: 'driver',
    },
    marketplaceCategoryOrder: {
        id: 'marketplaceCategoryOrder',
        sortId: 1.3,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.order',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.order',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/overview#c51813`,
        category: Category.MARKETPLACE,
        group: 'category',
        icon: 'order',
    },
    marketplaceOverview: {
        id: 'marketplaceOverview',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.overview',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.overview',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace`,
        category: Category.MARKETPLACE,
        group: 'rio',
        icon: 'shopping-cart',
    },
    marketplaceLatamOverview: {
        id: 'marketplaceLatamOverview',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.overview',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.overview',
        link: 'https://americas.rio.cloud/marketplace/overview',
        category: Category.MARKETPLACE,
        group: 'rio',
        icon: 'shopping-cart',
    },
    marketplaceConnectors: {
        id: 'marketplaceConnectors',
        sortId: 2.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.connectors',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.connectors',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/connectors`,
        category: Category.MARKETPLACE,
        group: 'rio',
        icon: 'compare',
    },
    marketplaceMANDS: {
        id: 'marketplaceMANDS',
        sortId: 3.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.manDigitalServices',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.manDigitalServices',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/man-digital-services`,
        category: Category.MARKETPLACE,
        group: 'man',
        icon: 'shopping-cart',
    },
    marketplaceMANNOW: {
        id: 'marketplaceNOW',
        sortId: 3.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.manNow',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.manNow',
        link: intl => `https://rio.cloud/${intl.locale.substring(0, 2)}/marketplace/man-digital-services/man-now`,
        category: Category.MARKETPLACE,
        group: 'man',
        icon: 'shopping-cart',
    },
};

const customercenterLinks: ServiceMap = {
    customercenterServicesLatam: {
        id: 'customercenterServicesLatam',
        sortId: 1.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bookedServices',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bookedServices',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/services',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'checkbox-checked',
    },
    customercenterAssetsLatam: {
        id: 'customercenterAssetsLatam',
        sortId: 1.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.vehiclesWithBookings',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.vehiclesWithBookings',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/assets',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'truck',
    },
    customercenterContractsLatam: {
        id: 'customercenterContractsLatam',
        sortId: 1.3,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.contracts',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.contracts',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/contracts',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'document',
    },
    customercenterHistoryLatam: {
        id: 'customercenterHistoryLatam',
        sortId: 1.4,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bookingHistory',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bookingHistory',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/history',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'time-alt',
    },
    customercenterInvoicesLatam: {
        id: 'customercenterInvoicesLatam',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bills',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bills',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/invoices',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'currency-euro',
    },
    customercenterUsageReportsLatam: {
        id: 'customercenterUsageReportsLatam',
        sortId: 2.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.usageReports',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.usageReports',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/usage',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'detail-view-info',
    },
    customercenterPaymentMethodLatam: {
        id: 'customercenterPaymentMethodLatam',
        sortId: 2.3,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.paymentMethod',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.paymentMethod',
        link: 'https://latam-marketplace.rio.cloud/customerCenter/paymentmethods',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'cost-efficency',
    },
    customercenterServices: {
        id: 'customercenterServices',
        sortId: 1.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bookedServices',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bookedServices',
        link: 'https://marketplace.rio.cloud/customerCenter/services',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'checkbox-checked',
    },
    customercenterAssets: {
        id: 'customercenterAssets',
        sortId: 1.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.vehiclesWithBookings',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.vehiclesWithBookings',
        link: 'https://marketplace.rio.cloud/customerCenter/assets',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'truck',
    },
    customercenterContracts: {
        id: 'customercenterContracts',
        sortId: 1.3,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.contracts',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.contracts',
        link: 'https://marketplace.rio.cloud/customerCenter/contracts',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'document',
    },
    customercenterHistory: {
        id: 'customercenterHistory',
        sortId: 1.4,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bookingHistory',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bookingHistory',
        link: 'https://marketplace.rio.cloud/customerCenter/history',
        category: Category.CUSTOMERCENTER,
        group: 'bookings',
        icon: 'time-alt',
    },
    customercenterInvoices: {
        id: 'customercenterInvoices',
        sortId: 2.1,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.bills',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.bills',
        link: 'https://marketplace.rio.cloud/customerCenter/invoices',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'currency-euro',
    },
    customercenterUsageReports: {
        id: 'customercenterUsageReports',
        sortId: 2.2,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.usageReports',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.usageReports',
        link: 'https://marketplace.rio.cloud/customerCenter/usage',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'detail-view-info',
    },
    customercenterPaymentMethod: {
        id: 'customercenterPaymentMethod',
        sortId: 2.3,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.paymentMethod',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.paymentMethod',
        link: 'https://marketplace.rio.cloud/customerCenter/paymentmethods',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'cost-efficency',
    },
    customercenterPartnerConnections: {
        id: 'customercenterPartnerConnections',
        sortId: 2.5,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.partnerConnections',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.PartnerConnections',
        link: 'https://marketplace.rio.cloud/customerCenter/partners',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'compare',
    },
    customercenterCompanySettings: {
        id: 'customercenterCompanySettings',
        sortId: 2.6,
        title: 'intl-msg:extendedMenu.linkTile.name.marketplace.companySettings',
        description: 'intl-msg:extendedMenu.linkTile.description.marketplace.companySettings',
        link: 'https://marketplace.rio.cloud/customerCenter/company',
        category: Category.CUSTOMERCENTER,
        group: 'additional',
        icon: 'building',
    },
};

export const serviceMap: ServiceMap = {
    ...services,
    ...adminLinks,
    ...exceptionLinks,
    ...marketplaceLinks,
    ...customercenterLinks,
};
