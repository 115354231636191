import { FormattedMessage } from 'react-intl';

const registrationLink = 'https://registration.iam.rio.cloud/';
const homeLink = 'https://home.rio.cloud';

const LoginButton = () => {
    const handleOpenLogin = () => window.open(homeLink, '_blank');

    return (
        <div className='text-center bg-white padding-top-25 padding-bottom-20'>
            <button type='button' className='btn btn-primary width-250 margin-bottom-20' onClick={handleOpenLogin}>
                <FormattedMessage id='intl-msg:extendedMenu.label.login' />
            </button>
            <div>
                <FormattedMessage id='intl-msg:extendedMenu.label.newClient' />
            </div>
            <a
                href={registrationLink}
                target='_blank'
                rel='noreferrer'
                className='btn btn-link btn-link-inline cursor-pointer'
            >
                <FormattedMessage id='intl-msg:extendedMenu.label.registerNow' />
            </a>
        </div>
    );
};

export default LoginButton;
