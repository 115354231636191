import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router';

import { useMenu } from '../../hooks/useMenu';
import QuickstartNavigation from './QuickstartNavigation';
import { useState } from 'react';

const MobileNavigation = () => {
    const intl = useIntl();

    const menuEntries = useMenu();

    const defaultEntry = menuEntries.find(i => i.default)?.id;

    const [selectedEntry, setSelectedEntry] = useState(defaultEntry || 'services');

    const [searchParams, setSearchParams] = useSearchParams();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchParams({ ...searchParams, id: event.target.value });
        setSelectedEntry(event.target.value);
    };

    return (
        <div id='mobile-nav' className='padding-x-20 margin-top-20'>
            <QuickstartNavigation />
            <select
                value={selectedEntry}
                className='form-control margin-top-20'
                id='menuItemListMobile'
                onChange={handleSelectChange}
            >
                {menuEntries.map(item => (
                    <option value={item.id} key={item.id}>
                        {intl.formatMessage({ id: item.title })}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default MobileNavigation;
