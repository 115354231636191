import { serviceMap, type UntranslatedServiceItem } from '../data/serviceData';
import compact from 'lodash/fp/compact';

export type AppResponse = {
    items: AppInfo[];
};

export type AppInfo = {
    id: string;
};

export const mapToServiceList = (appsResponse: AppResponse): UntranslatedServiceItem[] => {
    return compact(
        appsResponse.items.map((app: AppInfo): UntranslatedServiceItem | null => {
            return serviceMap[app.id] ?? null;
        })
    );
};
