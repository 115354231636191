import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages } from '../configuration/lang/langSlice';

/*

intl-msg:notifications.type
choose from "info", "success", "warning" or "danger"

intl-msg:notifications.serviceMessage
If empty, no message will be shown

intl-msg:notifications.serviceMessage.more
If empty, no more button and more content will be shown

https://app.phrase.com/editor/v4/accounts/4fcaa41a/projects/8c1220d39fc588e9ff9346adedef1b43?locales=%27629b468384c35d3dd63d8ba915d2ca3f%27&nav=l&search=query%253AServiceMessage%252Csorting%253ASCORE

*/

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const translationKeyExistsOrFallback = (key: string, fallback: any | undefined): string | undefined => {
    const localizedMessages = useAppSelector(getDisplayMessages);
    return localizedMessages && localizedMessages[key] !== undefined ? localizedMessages[key] : fallback;
};

const useServiceMessage = () => {
    const serviceMessage = translationKeyExistsOrFallback('intl-msg:notifications.serviceMessage', undefined);
    const hasMore = translationKeyExistsOrFallback('intl-msg:notifications.serviceMessage.more', false);
    const messageType = translationKeyExistsOrFallback('intl-msg:notifications.serviceMessage.type', undefined);

    return {
        serviceMessage,
        messageType,
        hasMore,
    };
};

export default useServiceMessage;
