import packageJson from '../package.json';

const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        APPS_URL: string | undefined;
        LOGOUT_URI?: string | undefined;
    };
    homeRoute: string | undefined;
    cms: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    marketplaceFreeTrialButtonUri: string;
    configCat: {
        apiKey: string;
    };
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        APPS_URL: import.meta.env.VITE_APPS_URL,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    cms: import.meta.env.VITE_CMS,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        // TODO: Request and supply your App's `client_id` as
        //       well as the needed OAuth scopes here
        clientId: '3c323ca3-6524-4cd3-9f3c-db7cce32953b',
        oauthScope: ['openid', 'profile', 'email', 'menu.read'],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'menu-web-2',
    marketplaceFreeTrialButtonUri: import.meta.env.VITE_FREE_TRAIL_URL,
    configCat: {
        apiKey: 'configcat-sdk-1/p0TbCGLmzEuBoeAX3R4FmA/Ocp6DQDsg0GoagP1wac_7Q',
    },
};
