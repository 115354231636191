import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import groupBy from 'lodash/fp/groupBy';

import { getLocale } from '../configuration/lang/langSlice';
import { useAppSelector } from '../configuration/setup/hooks';
import { useFetchAppsQuery } from '../services/appsApi';
import { Category, type GroupedItems, type ServiceItem, type UntranslatedServiceItem } from '../data/serviceData';
import { isLatamTenant } from '../configuration/tokenHandling/tokenSlice';
import { useFeatureFlag } from 'configcat-react';
import { fallbackServiceMap } from '../services/fallbackData';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';
import { reportErrorToSentry } from '../configuration/setup/sentry';

export type ServiceCategories = Record<string, ServiceItem[]>;

export const useData = () => {
    const locale = useAppSelector(getLocale);
    const tenantLatam = useAppSelector(isLatamTenant);
    const { data, error, isLoading } = useFetchAppsQuery(locale);
    const { value: fallbackEnabled } = useFeatureFlag('fallback', false);

    const intl = useIntl();

    const dataOrFallback: UntranslatedServiceItem[] | undefined =
        fallbackEnabled || isServerError(error) ? fallbackServiceMap(tenantLatam) : data;

    // Translate service name and description to allow sorting by name in
    // their respective category
    const translatedData = useMemo(() => {
        return dataOrFallback?.map(item => ({
            ...item,
            title: intl.formatMessage({ id: item.title }),
            description: intl.formatMessage({ id: item.description }),
            sortId: item.sortId,
            link: typeof item.link === 'function' ? item.link(intl) : item.link,
        }));
    }, [dataOrFallback]);

    // Group items by category for service pages
    const groupedItems = useMemo(() => {
        return groupBy('category')(translatedData) as unknown as GroupedItems;
    }, [translatedData]);

    const errorMessage = fallbackEnabled ? (
        <div className='alert alert-danger margin-bottom-20'>
            <div className='display-flex'>
                <span className='text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-info-sign' />
                <div className='flex-1-1'>
                    <div className='display-flex flex-wrap gap-5'>
                        <FormattedMessage id='intl-msg:extendedMenu.fallbackHint' />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        error && (
            <ErrorState
                headline={<FormattedMessage id='intl-msg:common-message.error.generic.headline' />}
                message={<FormattedMessage id='intl-msg:common-message.error.generic.message' />}
                fullWidth
            />
        )
    );

    return {
        data: translatedData,
        dataCategories: {
            [Category.SERVICES]: groupedItems[Category.SERVICES],
            [Category.EXCEPTIONS]: groupedItems[Category.EXCEPTIONS],
            [Category.ADMINISTRATION]: groupedItems[Category.ADMINISTRATION],
            [Category.MARKETPLACE]: groupedItems[Category.MARKETPLACE],
            [Category.CUSTOMERCENTER]: groupedItems[Category.CUSTOMERCENTER],
        } as ServiceCategories,
        error: errorMessage,
        loader: isLoading && (
            <div className='height-100vh display-flex align-items-center'>
                <Spinner isDoubleSized />
            </div>
        ),
    };
};

const isServerError = (error: FetchBaseQueryError | SerializedError | undefined): boolean => {
    const status = (error as FetchBaseQueryError | undefined)?.status;
    const serverError = typeof status === 'number' && status >= 500;
    if (serverError) {
        reportErrorToSentry(`Error retrieving data from server, using fallback data. Status code ${status}`);
    }
    return serverError;
};
