import { Category, serviceMap, type UntranslatedServiceItem } from '../data/serviceData';
import { type AppResponse, mapToServiceList } from './appsMapper';

const isLatamService = (id: string) => id.toLowerCase().includes('latam') || id === 'volksblocker';

const isAlwaysVisible = (id: string, service: UntranslatedServiceItem) =>
    service.category === Category.ADMINISTRATION || id === 'performance';

export const fallbackData = (latamTenant: boolean): AppResponse => {
    return {
        items: Object.entries(serviceMap)
            .filter(
                ([id, service]) =>
                    isAlwaysVisible(id, service) || (latamTenant ? isLatamService(id) : !isLatamService(id))
            )
            .map(([key]) => ({ id: key })),
    };
};

export const fallbackServiceMap = (latamTenant: boolean) => mapToServiceList(fallbackData(latamTenant));
