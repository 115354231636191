import classNames from 'classnames';

import { useMenu } from '../../hooks/useMenu';
import FreeTrialButton from '../freeTrail/FreeTrialButton';
import NavigationItem from './NavigationItem';
import QuickstartNavigation from './QuickstartNavigation';
import useServiceMessage from '../../hooks/useServiceMessage';

const Navigation = () => {
    const menuEntries = useMenu();

    const { serviceMessage, messageType } = useServiceMessage();

    const hasServiceMessage = serviceMessage !== '' && serviceMessage !== null;

    const wrapperClassName = classNames(
        'position-fixed',
        'height-100pct',
        'width-300',
        'overflow-y-auto',
        'display-flex',
        'flex-column',
        'justify-content-start',
        'padding-top-20',
        'padding-bottom-50',
        'padding-left-20',
        'gap-10'
    );

    return (
        <div className={wrapperClassName}>
            <QuickstartNavigation />

            <div className='display-flex flex-column flex-1-1'>
                <div id='desktop-nav' className='flex-1-1'>
                    <div id='menuItemListDesktop' className='nav nav-pills nav-pills-stacked-decent nav-stacked'>
                        {menuEntries.map(item => {
                            const isServiceItem = item.id === 'services';

                            return (
                                <NavigationItem
                                    key={item.id}
                                    {...item}
                                    infoIconIndicator={isServiceItem && hasServiceMessage}
                                    infoIconIndicatorColor={isServiceItem ? messageType : ''}
                                />
                            );
                        })}
                    </div>
                </div>

                <div id='free-trial' className='position-relative overflow-hidden rounded'>
                    <FreeTrialButton />
                </div>
            </div>
        </div>
    );
};

export default Navigation;
