import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import { config } from '../../config';
import { getLocale } from '../../configuration/lang/langSlice';
import { isLatamTenant } from '../../configuration/tokenHandling/tokenSlice';
import { useAppSelector } from '../../configuration/setup/hooks';
import { useAppContext } from '../../layout/AppContext';
import DarkModeSwitch from '../darkMode/DarkModeSwitch';
import { getTrackingAttributes } from '../../configuration/setup/googleAnalytics';

const Footer = () => {
    const locale = useAppSelector(getLocale);
    const tenantLatam = useAppSelector(isLatamTenant);
    const { isMobile } = useAppContext();

    if (!locale) {
        return null;
    }

    const [localeShort] = locale.split('-');
    const logoutUrl = config.backend.LOGOUT_URI || 'https://auth.iam.rio.cloud/logout';

    const dataProtectionUrl = tenantLatam
        ? 'https://americas.rio.cloud/rodape/protecao-de-dados'
        : `https://rio.cloud/${localeShort}/data-protection`;
    const legalUrl = tenantLatam ? 'https://americas.rio.cloud/legal-1' : `https://rio.cloud/${localeShort}/legal`;
    const cookiesUrl = tenantLatam
        ? 'https://americas.rio.cloud/rodape/protecao-de-dados'
        : `https://rio.cloud/${localeShort}/data-protection/cookies#c3322`;
    const imprintUrl = tenantLatam
        ? 'https://americas.rio.cloud/rodape/ficha-tecnica'
        : `https://rio.cloud/${localeShort}/imprint`;

    return (
        <>
            <div className={`display-flex ${isMobile ? 'gap-10' : 'gap-20'}`}>
                <Link
                    to={dataProtectionUrl}
                    target='_blank'
                    {...getTrackingAttributes({ trigger: 'click', action: 'footer::dataProtection' })}
                >
                    <FormattedMessage id='intl-msg:extendedMenu.label.dataProtection' />
                </Link>
                <Link
                    to={legalUrl}
                    target='_blank'
                    {...getTrackingAttributes({ trigger: 'click', action: 'footer::legal' })}
                >
                    <FormattedMessage id='intl-msg:extendedMenu.label.legal' />
                </Link>
                <Link
                    to={cookiesUrl}
                    target='_blank'
                    {...getTrackingAttributes({ trigger: 'click', action: 'footer::cookies' })}
                >
                    Cookies
                </Link>
                <Link
                    to={imprintUrl}
                    target='_blank'
                    {...getTrackingAttributes({ trigger: 'click', action: 'footer::imprint' })}
                >
                    <FormattedMessage id='intl-msg:extendedMenu.label.imprint' />
                </Link>
            </div>
            <div className='display-flex gap-20'>
                <DarkModeSwitch />
                <Link
                    to={logoutUrl}
                    target='_parent'
                    {...getTrackingAttributes({ trigger: 'click', action: 'footer::logout' })}
                >
                    <span className='rioglyph rioglyph-logout margin-right-5' />
                    <FormattedMessage id='intl-msg:extendedMenu.label.logout' />
                </Link>
            </div>
        </>
    );
};

export default Footer;
